import axios from 'axios';

class Ad {
  constructor (id = null, title, promo = false, srcSmImg, srcBigImg, description, text_ad, author) {
    this.id = id
    this.title = title
    this.promo = promo
    this.srcSmImg = srcSmImg
    this.srcBigImg = srcBigImg
    this.description = description
    this.text_ad = text_ad
    this.author = author
  }
}

export default {
  state: {
    ads: [],
    crAdId: []
  },
  mutations: {
    createAd (state, payload) {
      state.ads.push(payload)
    },
    loadAds (state, payload) {
      state.ads = payload
    },
    delAd (state, payload) {
      state.ads = state.ads.filter(ad => ad.id !== payload.id); 
    }
  },
  actions: {
    async createAd ({commit, getters}, payload) {
      commit('clearError')
      commit('setLoading', true)

      try {

        const ad = await axios.post(`https://vue2.fermka.ru/api/`, {
          request: "addAd",
          title: payload.title,
          promo: payload.promo,
          srcSmImg: payload.srcSmImg,
          srcBigImg: payload.srcBigImg,
          description: payload.description,
          text_ad: payload.text_ad,
          author: getters.user.id
        })
        
        // state.crAdId = ad.data[0].id
        // this.$store.state.crAdId = ad.data[0].id
        // this.state.crAdId.push(ad.data[0].id)
      //  const crAdIdR = ad.data[0].id
        // console.log(crAdIdR)
        //  console.log(state)

        commit('setLoading', false)
        commit('createAd', { 
          id: ad.data[0].id,
          title: ad.data[0].title,
          promo: ad.data[0].promo,
          srcSmImg: ad.data[0].srcSmImg,
          srcBigImg: ad.data[0].srcBigImg,
          description: ad.data[0].description,
          text_ad: ad.data[0].text_ad,
          author: ad.data[0].author,
          // newPost: '1'
          })
      } catch (error) {
        commit('setError', error.message)
        commit('setLoading', false)
        throw error
      }
    },
    async fetchAds ({commit}) {
      commit('clearError')
      commit('setLoading', true)

      const resultAds = []

      try {
        const allAds = await axios.post(`https://vue2.fermka.ru/api/`, {
          request: "allAds"
        })
        const ads = allAds.data
        ads.forEach(function(item) {
          // console.log(item)
          resultAds.push(
            new Ad(item.id, item.title, item.promo, item.srcSmImg, item.srcBigImg, item.description, item.text_ad, item.author  )
            )
        })

        commit('loadAds', resultAds)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error.message)
        commit('setLoading', false)
        throw error
      }
    },
    async delAd ({commit, getters}, payload) {
      commit('clearError')
      commit('setLoading', true)

      try {

        const delAd = await axios.post(`https://vue2.fermka.ru/api/`, {
          request: "delAd",
          id: payload.id
        })
        // this.state.ads.$remove(payload.id)
        // console.log(payload.id)
        // state.crAdId = ad.data[0].id
        // this.$store.state.crAdId = ad.data[0].id
        // this.state.crAdId.push(ad.data[0].id)
      //  const crAdIdR = ad.data[0].id
        // console.log(crAdIdR)
        //  console.log(state)

        commit('setLoading', false)
        commit('delAd', { 
          id: payload.id
          })
      } catch (error) {
        commit('setError', error.message)
        commit('setLoading', false)
        throw error
      }
    },
  },
  getters: {
    ads (state) {
      return state.ads
    },
    promoAds (state) {
      return state.ads.filter(ad => {
        return ad.promo === '1'
      })
    },
    myAds (state) {
      return state.ads
    },
    adById (state) {
      return adId => {
        return state.ads.find(ad => ad.id === adId)
      }
    }

  }
}
