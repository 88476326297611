import store from '../store'

export default function (to, from, next) {
  // const user = localStorage.getItem('user')
  if (localStorage.getItem('user')) {
    // console.log(store.getters.user)
    next()
  } else {
    next('/login?loginError=true')
    // console.log(store.getters.user)
  }
}
