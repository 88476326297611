<template>
  <div class="wrap">
    <div class="content">
      <v-layout row style="margin: 12px 0;">
        <v-flex xs12 sm8 offset-sm2>
          <!-- <h1 class="text--secondary mb-3">My ads</h1> -->

          <v-card
            class="elevation-4 mb-3"
            v-for="ad in myAds"
            :key="ad.id"
          >
            <v-layout row style="margin:0;">
              <v-flex xs12 sm4 class="img-flex">
                <v-img style="
                  height: calc(100% - 16px);
                  margin: 8px 0 8px 8px;" 
                  :src="ad.srcSmImg"
                ></v-img>
              </v-flex>
              <v-flex xs12 sm8 style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;" 
                  >
                <v-card-text>
                  <h2 style="margin-bottom: 8px;color: #00b3db;">{{ad.title}}</h2>
                  <p style="
                  margin-bottom: 0;
                  min-height: 100px;
                  max-height: 300px;
                  overflow: hidden;"
                  >{{ad.description}}</p>
                </v-card-text>
                <v-card-actions style="padding: 0 8px 8px 0;">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :to="'/ad/' + ad.id"
                  >Читать полностью</v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div class="footer">

    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      myAds () {
        return this.$store.getters.myAds
      }
    }
  }
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .img-flex{
    display: none;
  }
}
</style>
