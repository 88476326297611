<template>
  <v-container fluid>
    <v-layout row style="margin: 16px;">
      <v-flex xs12 >
        <v-form v-model="valid" ref="form" validation class="mb-3">
          <v-layout row>
            <v-flex xs12 style="display: flex;">
              <v-text-field
                name="title"
                label="Заголовок"
                type="text"
                v-model="title"
                required
                :rules="[v => !!v || 'Не заполнено']"
              ></v-text-field>

              <!-- <v-spacer></v-spacer> -->

              <v-switch
                label="Слайдер?"
                v-model="promo"
                color="primary"
                class="slider-btn"
              ></v-switch>
              
              <v-btn
                :loading="loading"
                :disabled="!valid || loading"
                class="createAd-btn"
                @click="createAd"
                color="success"
              >
                Сохранить
              </v-btn>

            </v-flex>
          </v-layout>
          <v-layout row>

            <v-text-field
              name="srcSmImg"
              label="URL изображения в слайдере"
              type="text"
              v-model="srcSmImg"
              multi-line
              :rules="[v => !!v || 'Не заполнено']"
            ></v-text-field>

            <v-text-field
              name="srcBigImg"
              label="URL изображения на странице"
              type="text"
              v-model="srcBigImg"
              multi-line
              :rules="[v => !!v || 'Не заполнено']"
            ></v-text-field>

          </v-layout>
          <v-layout row>
            <v-text-field
              name="description"
              label="Короткое описание"
              type="text"
              v-model="description"
              multi-line
              :rules="[v => !!v || 'Не заполнено']"
            ></v-text-field>

            <ckeditor
            v-model="text_ad" 
            :config="editorConfig"
            name="text_ad"
            ></ckeditor>

          </v-layout>
        </v-form>

      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>


  export default {
    data () {
      return {
        title: '',
        promo: false,
        srcSmImg: 'https://vue2.fermka.ru/assets/img/screen.png',
        srcBigImg: 'https://vue2.fermka.ru/assets/img/bg_concept_40_.jpg',
        description: '',
        valid: false,
        text_ad: 'Наполните страницу контентом ...',
        editorConfig: {
          filebrowserUploadMethod: 'form',
          extraPlugins: 'uploadimage',
          filebrowserUploadUrl: 'libs/ckfinder/ckfinder.html',
          filebrowserBrowseUrl: 'libs/ckfinder/ckfinder.html',
          extraPlugins: 'codemirror,youtube',
          codemirror: {
            lineNumbers: true,
            enableCodeFolding: true,
            enableCodeFormatting: true,
            autoFormatOnStart: true,
            autoFormatOnModeChange: true
          }
        }
      }
    },
    computed: {
      loading () {
        return this.$store.getters.loading
      }
    },
    methods: {
      createAd () {
        if (this.$refs.form.validate()) {
          const ad = {
            title: this.title,
            promo: this.promo,
            srcSmImg: this.srcSmImg,
            srcBigImg: this.srcBigImg,
            description: this.description,
            text_ad: this.text_ad
          }

          this.$store.dispatch('createAd', ad)
            .then(() => {
              const myarray = this.$store.getters.ads
              const foo = myarray[myarray.length - 1];
              this.$router.push('/ad/'+ foo.id)
            })
            .catch(() => {})
        }
      },
      
    }
  }
</script>

<style scoped>
.slider-btn{
  margin: 0 16px;
  padding-top: 22px;
}
.createAd-btn{
  margin: auto 0;
}
</style>
