

export default {

    state: {
        streams: [
          

          {
            name: 'TranceRadio',
            url: 'https://strm112.1.fm/atr_mobile_mp3',
          },

          { 
            name: 'EuropaPlus', 
            url: 'https://online2.gkvr.ru:8000/europa_nsb_64.aac', 
          },

          {
            name: 'ParadiseRadio',
            url: 'https://stream-uk1.radioparadise.com/aac-128',
          },


          {
            name: 'Top40Radio',
            url: 'https://strm112.1.fm/top40_mobile_mp3',
          },

          { 
            name: 'AcavilleRadio', 
            url: 'https://acaville.out.airtime.pro:8000/acaville_b', 
          },

          

          

        ],
        //volumeSlider:'1',
        currentvolume: '1',
        playToggleText: '▶',
        playstatus: 0,
        bufferstatus: 0,

        currentstreamurl: '',
        currentstation: 'Pick a station',
        currentstationdesc: undefined,
        currenttrack: 'Not Playing',
        currentbitrate: undefined,
    },

    mutations: {
        //updatecurrentstation: state => state.stationname,
        
        //updatecurrenttrack: state => state.currenttrack,

        updatecurrentstreamurl (state, payload) {
          state.currentstreamurl = payload.newstreamurl
        },

        updatecurrentstation (state, payload) {
          state.currentstation = payload.newstation
        },

        updatecurrentstationdesc (state, payload) {
          state.currentstationdesc = payload.newstationdesc
        },

        updatecurrenttrack (state, payload) {
          state.currenttrack = payload.newtrack
        },

        updatecurrentbitrate(state, payload) {
          state.currentbitrate = payload.newbitrate
        },

        updatevolume (state, payload) {
          state.currentvolume = payload.newvolume
        },

        updateplaystatus (state, payload) {
          state.playstatus = payload.newplaystatus
        },

        updatebufferstatus (state, payload) {
          state.bufferstatus = payload.newbufferstatus
        }
    },

    actions: {
        //
    },

    getters: {
      myRadio (state) {
        return state.streams
      }
    }

}
