import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import '@babel/polyfill'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import CKEditor from 'ckeditor4-vue';
 




Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VuePlyr, {
  plyr: {}
})

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.use( CKEditor );

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    this.$store.dispatch('fetchAds')
  }
}).$mount('#app')


