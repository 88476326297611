import axios from 'axios';

class User {
  constructor (id,is_admin,name,job) {
    this.id = id
    this.is_admin = is_admin
    this.name = name
    this.job = job
  }
}

export default {
  state: {
    user: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    async registerUser ({commit}, {email, password}) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const user = await axios.post(`https://vue2.fermka.ru/api/`, {
          request: "addContact",
          email: email,
          password: password
        })
        commit('setUser', new User(user.data[0].id))
        commit('setLoading', false)
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error.message)
        throw error
      }
    },
    async loginUser ({commit}, {email, password}) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const user = await axios.post(`https://vue2.fermka.ru/api/`, {
          request: "loginUser",
          email: email,
          password: password
        })
        commit('setUser', new User(
          user.data[0].id,
          user.data[0].is_admin,
          user.data[0].name,
          user.data[0].job,
          ))
        commit('setLoading', false)
        localStorage.setItem('user', user.data[0].id)
        
        // console.log(localStorage.getItem('user'))

        // localStorage.setItem('ключ', 'значение') - запись
        // localStorage.getItem('ключ') - получить значение по ключу
        // localStorage.removeItem("ключ") - удалить значение по ключу
        // localStorage.clear() - очистить весь localStorage

      } catch (error) {
        commit('setLoading', false)
        commit('setError', 'Отказ авторизации, ошибки в полях, либо пользователь не найден!')
        // console.log(error)
        throw error
      }
    },
    autoLoginUser ({commit}) {
      commit('setUser', new User(localStorage.getItem('user')))
    },
    logoutUser ({commit}) {
      localStorage.removeItem("user")
      commit('setUser', null)
    },
    // axios.post(`http://spa/vuecurs/vue2/public/api/`, {request: "allContacts"})
          // .then(response => {
          //   // console.log(response.data)
          //   this.orders = response.data
          // })
          // .catch(e => {
          //   this.errors.push(e)
          // })
  },
  getters: {
    user (state) {
      return state.user
    },
    isUserLoggedIn (state) {
      return state.user !== null
    }
  }
}
