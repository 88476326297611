<template>
  <div >
    <div class="head">
      Список станций:
    </div>

    <ul class="ul">       
        <li
          v-for="item in myRadio" :key="item.id"
          @click="getPlayFile(item.url, item.name, $event)"> 
          {{item.name}}
        </li>
    </ul>
    
    <div class="bodyPl">
      <h2>{{ radioName }}</h2>
      <audio controls="true" id="audioRadio" ref="audio" :src="radioPlayer"></audio>
    </div>




    <!-- <vue-plyr>
      <audio controls crossorigin playsinline>
        <source
          src="https://strm112.1.fm/top40_mobile_mp3"
          type="audio/mp3"
        />
      </audio>
    </vue-plyr> -->
  </div>
</template>

<script>



export default {
  

  data: () => ({
    radioPlayer: 'https://stream-uk1.radioparadise.com/aac-128',
    radioName: 'ParadiseRadio',
    
  }),
  computed: {      
      myRadio () {
        return this.$store.getters.myRadio
      }
  },
  methods: {
    getPlayFile(urlStr, nameStr, event){
      if(event.target.className === 'activeRadio'){
        // this.$refs.audio.volume = 0.2
      }else{
        this.radioPlayer = urlStr
        this.radioName = nameStr
        
      }
    },
   
},
mounted: function(){
    this.$refs.audio.volume = 0.2
  }
}
</script>
<style scoped>
/* .plyr__menu,
.plyr__progress__container,
.plyr__time{
  display: none!important;
} 
 .plyr__controls .plyr__controls__item:first-child {
    margin-right: 0!important;
}
.plyr__controls {
    justify-content: space-between !important;
} */
.ul{
  overflow: auto;
  height: 95px;
  padding-left: 0px!important;
  list-style: none;
  padding-right: 20px;
}
.ul li:hover {
  cursor: pointer;
  color: #00b3db!important;
}
.activeRadio{
  color:#00b3db;
}
.NoActiveRadio{
  color: #000;
}
#audioRadio{
  width: 220px;
}
.head{
  color: #17171894;
  font-weight: bold;
  background-color: #dde1e2;
  margin: -14px -14px 15px -14px;
  padding: 15px;
}
.bodyPl{
  margin-top:20px;
  display: flex;
  flex-direction: column;
  width: 220px;
}
.bodyPl h2{
  margin-bottom:5px;
   color:#00b3db
}
</style>