<template>
    <div class="wrap">
        <div class="content"></div>
        <div class="footer"></div>
    </div>
                 
    
</template>

<script>
  export default {
    metaInfo: {
    title: 'Описание проекта. Цели и задачи.'
    },
    data () {
      return {
        drawer: true,
        items: [
          { title: 'Home', icon: 'mdi-home-city' },
          { title: 'My Account', icon: 'mdi-account' },
          { title: 'Users', icon: 'mdi-account-group-outline' },
        ],
        mini: true,
      }
    },
  }
</script>

<style scoped>


</style>