import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'
import Home from '@/components/Home'
import Ad from '@/components/Ads/Ad'
import AdList from '@/components/Ads/AdList'
import NewAd from '@/components/Ads/NewAd'
import Login from '@/components/Auth/Login'
import Registration from '@/components/Auth/Registration'
import Orders from '@/components/User/Orders'
import Settings from '@/components/Settings'
import About from '@/components/User/About'
import NotFoundComponent from '@/components/NotFoundComponent'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/new', name: 'new', component: NewAd , beforeEnter: AuthGuard },
  { path: '/orders', name: 'orders', component: Orders , beforeEnter: AuthGuard },
  { path: '/list', name: 'list', component: AdList , beforeEnter: AuthGuard },
  { path: '/login', name: 'login', component: Login },
  { path: '/registration', name: 'reg', component: Registration , beforeEnter: AuthGuard },
  { path: '/settings', name: 'sett', component: Settings },
  { path: '/about', name: 'about', component: About },
  { path: '/ad/:id', props: true, name: 'ad', component: Ad },
  { path: '*', name: '404', component: NotFoundComponent }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
