<template>
  <v-app>
    <v-item-group>
    <v-navigation-drawer app 
      style="transform: none;"
      v-model="drawer"
      absolute
      temporary
      right
    >
        <v-list
          nav
          dense
        >

        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-close</v-icon>
        </v-btn>

         
        <v-list-item link
          v-for="link of links"
          :key="link.title"
          :to="link.url"
        >
          <v-list-item-icon>
            <v-icon>{{link.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="link.title"></v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>



    <v-container style="padding:0;">
     <v-card 
      style="z-index: 1;"
      flat
      tile
    >
      <v-toolbar 
      class="vtlbr"
      dense
      style="height:60px; padding:5px 0;box-shadow: none;"
      >
        
      
          <router-link
            to="/"
            flat
            class="logo"
          >
        
            <v-list-item-avatar>
              <v-img alt="Logo" src="https://vue2.fermka.ru/assets/img/logo.png"></v-img>
            </v-list-item-avatar>
            <div>
              <v-toolbar-title class="logo-title d-none d-sm-flex">ФЕРМКА</v-toolbar-title>
              <div class="slogan d-none d-sm-flex">Стремление к созиданию</div>
            </div>

          </router-link>
       
        <v-spacer></v-spacer>

        <input  v-if="isUserLoggedIn" class="tf-search" placeholder="Найти в заказах ..." >
  
        <v-btn  v-if="isUserLoggedIn" icon style="margin: 0 20px 0 -65px;height: 40px;width: 40px;">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>


        <v-toolbar-items class="hidden-sm-and-down">

         


        <v-list-item link
          v-for="link in links"
          :key="link.title"
          :to="link.url"
          flat
          class="btn-top-menu"
        >
          <v-icon left>{{link.icon}}</v-icon>
          {{link.title}}
        </v-list-item>
      </v-toolbar-items>

        <v-btn icon
        to="/settings"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <!-- <v-btn icon
          @click="onLogout"
          v-if="isUserLoggedIn"
        >
          <v-icon>mdi-location-exit</v-icon>
        </v-btn> -->


        <!-- <v-tooltip bottom v-if="isUserLoggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
              v-bind="attrs"
              v-on="on"
              @click="onLogout"
              
            >
              <v-icon>mdi-location-exit</v-icon>
            </v-btn>
          </template>
          <span>ВЫХОД</span>
        </v-tooltip> -->



        <v-app-bar-nav-icon 
          @click.stop="drawer = !drawer" 
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>

      </v-toolbar>
    </v-card>
    </v-container>

    <v-main >
        <router-view></router-view>
    </v-main>
    <!-- <v-footer app>
      
    </v-footer> -->

    <div>
        <v-btn icon
          color="primary"
          class="btn-radio elevation-4"
          @click="radioalert = !radioalert, btnRadioIcoShow ()"
        >
          <v-icon>{{btnRadioIco}}</v-icon>
        </v-btn>
      <v-alert
        class="radio elevation-20"
        :value="radioalert"
        transition="scale-transition"
      >
        <radio></radio>
      </v-alert>
    </div>


   


   
    </v-item-group>

    <template v-if="error"> 
      <v-snackbar  style="z-index:99;"
        :timeout="20000"
        :value="true"
        absolute
        center
        shaped
        bottom
        :multi-line="true"
        @input="closeError"
      >
        <p class="error-snac">
          {{error}}
          <v-btn class="btn-close-error" icon @click.native="closeError"><v-icon>mdi-close</v-icon></v-btn>
        </p>
      </v-snackbar>
    </template>
<!-- {{showUser}}  -->
  </v-app>
</template>

<script>
import Radio from './components/Radio/Radio.vue';


export default {
  metaInfo: {
    title: 'Тестовая площадка VUE'
  },
  components: { Radio },
  name: 'App',

  data: () => ({
    // showUser: showUser,
    drawer: null,
    expand: false,
    radioalert: false,
    btnRadioIco: 'mdi-volume-low',
    // links: [
        
    //     {title: 'БАЗА', icon: 'mdi-database-arrow-left-outline', url: '/orders'},
    //     {title: 'Добавить', icon: 'mdi-folder-plus-outline', url: '/new'},
    //     {title: 'СТЕНА', icon: 'mdi-playlist-edit', url: '/list'},
    //     {title: 'ВХОД', icon: 'mdi-account-lock-outline', url: '/login'},
    //     // {title: 'Регистрация', icon: 'mdi-target-variant', url: '/registration'},
    //   ],
    
  }),
  computed: {
    error () {
      return this.$store.getters.error
    },
    // showUser () {
    //   console.log(this.$store.getters.user)
    //   return this.$store.getters.user+'11111111'
      
    // },
    isUserLoggedIn () {
      return this.$store.getters.isUserLoggedIn
    },
    links () {
      if (this.isUserLoggedIn) {
        return [
          {title: 'БАЗА', icon: 'mdi-database-arrow-left-outline', url: '/orders'},
          // {title: 'Добавить', icon: 'mdi-folder-plus-outline', url: '/new'},
          {title: 'СТЕНА', icon: 'mdi-playlist-edit', url: '/list'},
        ]
      }

      return [
        {title: 'ВХОД', icon: 'mdi-account-lock-outline', url: '/login'},
     // {title: 'Регистрация', icon: 'mdi-target-variant', url: '/registration'},
      ]
    }
  
  },
  methods: {
    closeError () {
      this.$store.dispatch('clearError')
    },
    // onLogout () {
    //   this.$store.dispatch('logoutUser')
    //   this.$router.push('/').catch(()=>{});
    // },
    btnRadioIcoShow (){
      const audelem = document.getElementById('audioRadio')
      if(this.btnRadioIco === 'mdi-close'){
        if(audelem.paused){
        this.btnRadioIco = 'mdi-volume-low'
        }else{
          this.btnRadioIco = 'mdi-volume-high'
        }
      }else{
        this.btnRadioIco = 'mdi-close'
      }
    }
  },
  created () {
    if(localStorage.getItem('user')){
      this.$store.dispatch('autoLoginUser', localStorage.getItem('user'))
      // console.log(localStorage.getItem('user'))
    }
  },
  // mounted: function(){
  //   this.loadHome()
  // }
};




</script>

<style>


::selection {background: #777;color:#fff;}
::-moz-selection {background: #777;color:#fff;}
::-webkit-selection {background: #777; color:#fff;}

::-webkit-input-placeholder {
	text-indent: 0;
	-webkit-transition: text-indent .8s ease;
	-o-transition: text-indent .8s ease;
	transition: text-indent .8s ease;
}

::-moz-placeholder {
	text-indent: 0;
	-webkit-transition: text-indent .8s ease;
	-o-transition: text-indent .8s ease;
	transition: text-indent .8s ease;
}

:-moz-placeholder {
	text-indent: 0;
	-webkit-transition: text-indent .8s ease;
	-o-transition: text-indent .8s ease;
	transition: text-indent .8s ease;
}

:-ms-input-placeholder {
	text-indent: 0;
	-webkit-transition: text-indent .8s ease;
	-o-transition: text-indent .8s ease;
	transition: text-indent .8s ease;
}

:focus::-webkit-input-placeholder {
	text-indent: 500px;
	color: transparent;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}

:focus::-moz-placeholder {
	text-indent: 500px;
	color: transparent;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}

:focus:-moz-placeholder {
	text-indent: 500px;
	color: transparent;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}

:focus:-ms-input-placeholder {
	text-indent: 500px;
	color: transparent;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}
::-webkit-scrollbar-button {
	width: 10px;
	height: 0px;
	background-image: url('');
	background-repeat: no-repeat;
}

::-webkit-scrollbar-track {
	background-color: #ecedee;
}

::-webkit-scrollbar-thumb {
	border-radius: 50px;
	background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #999;
}

::-webkit-resizer {
	width: 10px;
	height: 0px;
	background-image: url('');
	background-repeat: no-repeat;
}


::-webkit-scrollbar {
	width: 9px;
}
.logo{
  width: 200px;
  padding-left: 0!important;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.logo-title{
  font-size: 30px!important;
  margin: -7px 0 0 0;
}
.slogan{
  font-size: 10px;
  margin: -10px 0 0 3px;
}
/* .v-toolbar__content {
    padding: 4px 0px;
} */
.logo.theme--light.v-list-item--active:before, 
.logo.theme--light.v-list-item--active:hover:before, 
.logo.theme--light.v-list-item:focus:before {
  opacity: 0;
}
.btn-top-menu.v-list-item--active{
  background-color: #00b3db;
  color: #fff;
  margin: -7px 0;
}
.btn-top-menu.v-list-item:hover{
  margin: -7px 0;
}
.btn-top-menu.v-list-item--active .v-icon::before{
  color: #fff;
}
.tf-search{
  outline: none;
  width: 100%;
  border: 1px solid #00000014;
    padding: 7px 38px 7px 18px;
    border-radius: 20px;
    box-shadow: inset 2px -2px 4px #00000000, inset -4px 4px 8px #302e2e40;
    background-color: #dde1e2;
    margin-right: 24px;
}
.v-main{
  min-height: calc(100vh - 60px);
 
}
.vcarusel,
.v-image.v-responsive.v-carousel__item.theme--light{
  height: calc(100vh - 61px)!important;
}
.theme--dark.v-btn.v-btn--icon {
    color: #00b3ff!important;
}
.v-window__next, .v-window__prev {
    background: none!important;
    /* box-shadow: 0 1px 1px 1px #000; */
}
.radio{
  z-index: 10;
  position: fixed !important;
  background-color: #fff;
  left: 60px;
  bottom: 0px;
  /* border: 1px solid #00b3db!important; */
}
.btn-radio{
  z-index: 10;
  position: fixed !important;
  background-color: #fff;
  left: 16px;
  bottom: 16px;
  /* border: 1px solid #00b3db; */
}
.v-overlay--active,.v-navigation-drawer{
  z-index: 9999!important;
}
.wrap{
  box-shadow: inset 0px 15px 10px -20px #000;
  height: 100%;
  background-image: url(https://vue2.fermka.ru/assets/img/bg_concept_40-2_.jpg);
  background-repeat: no-repeat;
  background-position:top center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.error-snac{
  margin: 0 10px 0 0!important;
}
.btn-close-error{ 
  position: absolute!important;
  top: 0;
  right: 0;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
  height: 70px;
  background-color: #dde1e2;
  box-shadow: 0px 50px 50px 60px #dde1e2;
  border-top: 2px solid #fff;
}
/* .v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 0 10px -5px #000;
} */
@media (min-width: 1904px){
.container {
    max-width: 1185px;
}
}
@media screen and (min-width: 960px) {
  .vtlbr .v-toolbar__content {
    padding: 4px 0px !important;
  } 
}
 @media screen and (min-width: 600px) {
  .tf-search{
      width: 200px;
    }
 }

 @media screen and (min-width: 1900px) { 
  h1{
    font-size: 4em;
  }
  h2{
    font-size: 2.5em;
  }
  p{
    font-size: 2em;
  }
  .video-info{
    width: 480px!important;
  }
}

</style>
