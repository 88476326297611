<template>
    <div class="wrap">
        <div class="content">

          <div class="lcol">
            <v-card class="list elevation-3">
              <div class="head">РАЗДЕЛЫ</div>
              <div class="body-card">
             
             
              
              </div>
            </v-card>
          </div>

          <div class="ccol">
            <v-card class="cont elevation-3">
              <div class="head">НАСТРОЙКИ</div>
              <div class="body-card">

    
                <!-- <ckeditor v-model="editorData" :config="editorConfig"></ckeditor> -->

  
              
              </div>             
            </v-card>
          </div>

          <div class="rcol">
            <v-card class="login elevation-3">
              <div class="head">ПРОФИЛЬ</div>
              <div class="body-card">

                {{this.$store.getters.user}}
              
              
              </div>
            </v-card>

            <!-- <v-tooltip bottom v-if="isUserLoggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  @click="onLogout"
                  class="btn-exit"
                >
                  <v-icon>mdi-location-exit</v-icon>
                </v-btn>
              </template>
              <span>ВЫХОД</span>
            </v-tooltip> -->
          </div>    

        </div>
        <div class="footer">

        </div>
    </div>
</template>

<script>


  export default {
    metaInfo: {
    title: 'Страница настроек'
    },
    data: () => ({

    //   editorConfig: {
    //    filebrowserUploadMethod: 'form',
    //    extraPlugins: 'uploadimage',
    //    filebrowserUploadUrl: 'libs/ckfinder/ckfinder.html',
    //    filebrowserBrowseUrl: 'libs/ckfinder/ckfinder.html'
    //  }
    
  }),
  computed: {
    error () {
      return this.$store.getters.error
    },
    isUserLoggedIn () {
      return this.$store.getters.isUserLoggedIn
    },
  },
  methods: {
    closeError () {
      this.$store.dispatch('clearError')
    },
    onLogout () {
      this.$store.dispatch('logoutUser')
      this.$router.push('/').catch(()=>{});
    },
  },
  created () {
    if(localStorage.getItem('user')){
      this.$store.dispatch('autoLoginUser', localStorage.getItem('user'))
    }
  },

 
  }
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
.head{
  color: #17171894;
  font-weight:500;
  background-color: #dde1e2;
  padding: 10px;
  border-radius:4px 4px 0 0!important;
  margin-bottom: 4px;
}
.body-card{
  height: calc(100vh - 216px);
  overflow: auto;
  padding: 6px 12px 0 6px;
}
.lcol{
  width: 360px;
  height: 100%;
}
.lcol .list{
  height: calc(100% - 12px);
  margin: 6px 0 6px 6px;
  padding: 2px;
}
.ccol{
  width: 100%;
  height: 100%;
  /* border: 2px dotted #f6f6f6; */
}
.ccol .cont{
  height: calc(100% - 12px);
  margin: 6px;
  padding: 2px;
}
.rcol{
  width: 360px;
  height: 100%;
}
.rcol .login{
  height: calc(100% - 12px);
  margin: 6px 6px 6px 0;
  padding: 2px;
}
.btn-exit{
  position: absolute;
  top: 11px;
  right: 12px;
  background-color: #fff;
  color: red;
}
@media screen and (max-width: 900px) {
  .content {
    flex-direction: column;
  }
  .rcol, .lcol{
    width: 100%;
  }
  .rcol{
    order: -1;
  }
  .ccol{
    order: 0;
  }
  .lcol{
    order: 1;
  }
  .lcol .list,.ccol .cont,.rcol .login{
    margin: 0;
    padding: 16px;
    height: 100%;
    box-shadow: none!important;
    border-radius: 0;
  }

}
</style>