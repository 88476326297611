<template>
    <div class="wrap">
        <div class="content">

          <div class="lcol">
            <v-navigation-drawer
              class="login1 elevation-3"
              :mini-variant.sync="mini1"
              permanent
              v-click-outside="onClickOutsideStandard1"
              @click.stop="mini1 = false"
            >
              <v-list dense>
                
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @click="getCompFile(item.url, $event)"
                  :ref="item.url"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon
                      v-bind="attrs"
                      v-on="on"
                      >
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </template>
                    <span>{{ item.title }}</span>
                  </v-tooltip>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
              </v-list>
            </v-navigation-drawer>
          </div>

          <div class="ccol">
            <v-card class="cont elevation-3">
              <div class="head">{{titleTerminal}}</div>
              <div id="order-terminal" class="body-card ">

                <new-ad
                 ref="new-ad" 
                 class="comp"
                 :class="{ active: newAd }"
                 ></new-ad>

                <order-mg
                 ref="order-mg" 
                 class="comp"
                :class="{ active: orderMg }"
                ></order-mg>

                <registration
                 ref="registration" 
                 class="comp"
                 :class="{ active: accountPlus }"
                 ></registration>
              
              </div>             
            </v-card>
          </div>
         
          <div  class="rcol">
            <v-navigation-drawer
              class="login elevation-3"
              :mini-variant.sync="mini"
              permanent
              v-click-outside="onClickOutsideStandard"
              @click.stop="mini = false"
            >
              <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img src="https://vue2.fermka.ru/assets/img/matrix-rain.gif"></v-img>
                </v-list-item-avatar>

                <v-list-item-title>John Leider</v-list-item-title>

                <v-btn
                  icon
                  @click.stop="mini = !mini"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item>

              <!-- <v-divider></v-divider> -->

              <!-- <v-list dense>
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @click="getCompFile(item.url, $event)"
                  :ref="item.url"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

              <v-list-item
                class="btn-logout"
                @click.stop="dialogExit = true"
              >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon
                      v-bind="attrs"
                      v-on="on"
                      >
                        <v-icon>mdi-location-exit</v-icon>
                      </v-list-item-icon>
                    </template>
                  <span>Выйти</span>
                </v-tooltip>

                <v-list-item-content>
                  <v-list-item-title>Выйти из системы</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


                <v-dialog
                  v-model="dialogExit"
                  max-width="240"
                >
                  <v-card>
                    <v-card-title >
                      Выйти из системы?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="dialogExit = false"
                      >
                        НЕТ
                      </v-btn>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="onLogout"
                      >
                        ДА
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </v-navigation-drawer>
          </div>



        </div>
        <div class="footer">

        </div>
    </div>
</template>



<script>
import NewAd from '@/components/Ads/NewAd.vue';
import OrderMg from '@/components/User/OrderMg.vue';
import Registration from '@/components/Auth/Registration.vue';
import axios from 'axios';

export default {
  components: { NewAd,OrderMg,Registration },
  data() {
    return {
      items: [
        { title: 'База заказов', icon: 'mdi-order-bool-descending-variant', url: 'order-mg'},
        { title: 'Добавить на стену', icon: 'mdi-folder-plus-outline', url: 'new-ad'},
        { title: 'Регистрация', icon: 'mdi-account-plus', url: 'account-plus'},
      ],
      mini: true,
      mini1: true,
      dialogExit: false,
      orderMg: true,
      newAd: false,
      accountPlus: false,
      titleTerminal: 'БАЗА ЗАКАЗОВ'
    }
  },
  methods: {
    getCompFile (url){
  //     let elem = document.getElementById('order-terminal');

  // // сделать его фон красным
  // elem.style.background = 'red';
  //  console.log(url)
  // this.$refs.url

      if(url == 'order-mg'){
        this.titleTerminal = 'БАЗА ЗАКАЗОВ'
        this.orderMg = true
        this.newAd = false
        this.accountPlus = false
      } else if(url == 'new-ad'){
        this.titleTerminal = 'ДОБАВИТЬ НА СТЕНУ'
        this.newAd = true
        this.orderMg = false
        this.accountPlus = false
      } else if(url == 'account-plus'){
        this.titleTerminal = 'РЕГИСТРАЦИЯ'
        this.accountPlus = true
        this.newAd = false
        this.orderMg = false
      } else {

      }
    },
    onLogout () {
      this.$store.dispatch('logoutUser')
      this.$router.push('/').catch(()=>{});
    },
    onClickOutsideStandard () {
      this.mini = true
    },
    onClickOutsideStandard1 () {
      this.mini1 = true
    },
  },

  created() {
    // axios.post(`https://vue2.fermka.ru/api/`, {request: "get_Order"})
    // .then(response => {
    //   console.log(response.data)
    //   this.orders = response.data
    // })
    // .catch(e => {
    //   this.errors.push(e)
    // })
  }
}
// if ($order['status_id'] == 0) {$status_str = " не подтвержден ";}
// if ($order['status_id'] == 1) {$status_str = " ожидает оплаты ";}
// if ($order['status_id'] == 2) {$status_str = " оплачен ";}
// if ($order['status_id'] == 3) {$status_str = " в доставке ";}
// if ($order['status_id'] == 4) {$status_str = " отменен ";}
// if ($order['status_id'] == 5) {$status_str = " выполнен ";}
// if ($order['status_id'] == 6) {$status_str = " в обработке ";}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
.head{
  color: #17171894;
  font-weight:500;
  background-color: #dde1e254;
  padding: 10px;
  border-radius:4px 4px 0 0!important;
  margin-bottom: 4px;
}
.body-card{
  height: calc(100vh - 196px);
  overflow: auto;
  padding: 6px 12px 0 6px;
}
.lcol{
  height: 100%;
}
.lcol .list{
  height: calc(100% - 12px);
  margin: 6px 0 6px 6px;
  padding: 2px;
}
.ccol{
  width: 100%;
  height: 100%;
  /* border: 2px dotted #f6f6f6; */
}
.ccol .cont{
  height: calc(100% - 12px);
  margin: 6px;
  padding: 2px;
}
.rcol{
  height: 100%;
}
.login{
  height: calc(100% - 12px)!important;
  margin: 6px 0px;
  border-radius: 4px 0 0 4px;
}
.login1{
  height: calc(100% - 12px)!important;
  margin: 6px 0px;
  border-radius: 0 4px 4px 0;
}
.btn-exit-panel-profile{
  position: absolute;
  right: 0px;
  top: 5px;
}
.btn-logout{
  bottom: 0;
  position: absolute;
  width: 100%;
}
.comp{
  display: none!important;
}
.comp.active{
  display: inline-block!important;
  width: 100%;
}
/* @media screen and (max-width: 900px) {
  .content {
    flex-direction: column;
  }
  .rcol, .lcol{
    width: 100%;
  }
  .rcol{
    position: absolute;
    z-index: 1;
  }
  .ccol{
    order: 0;
    margin: 0 -56px 0 56px;
    z-index: 2;
  }
  .lcol{
    order: 1;
    padding-left: 56px;
    z-index: 2;
  }
  .lcol .list,.ccol .cont,.rcol .login{
    margin: 0;
    height: 100%;
    box-shadow: none!important;
    border-radius: 0;
  }

} */
</style>



