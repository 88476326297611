<template>
   <div class="wrap">
        <div class="content log-ctnt">

        <v-card class="login elevation-4">
          <div class="head">
            ВХОД
          </div>
          <v-card-text>
            <v-form v-model="valid" ref="form" validation>
              <v-text-field
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
                v-model="email"
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                name="password"
                label="Пароль"
                type="password"
                :counter="6"
                v-model="password"
                :rules="passwordRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
              :disabled="!valid || loading"
            >ВОЙТИ</v-btn>
          </v-card-actions>
        </v-card>
    </div>
    <div class="footer">
      
    </div>
</div>
    
</template>

<script>
// import axios from 'axios';
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  export default {
    metaInfo: {
      title: 'Страница входа в систему'
    },

    data () {
      return {
        email: '',
        password: '',
        valid: false,
        emailRules: [
          v => !!v || 'E-mail обязателен!',
          v => emailRegex.test(v) || 'E-mail не валидный'
        ],
        passwordRules: [
          v => !!v || 'Пароль обязателен!',
          v => (v && v.length >= 6) || 'Пароль должен содержать больше 6 символов'
        ]
      }
    },
    computed: {
      loading () {
        return this.$store.getters.loading
      }
    },
    methods: {
      onSubmit () {
        if (this.$refs.form.validate()) {
          const user = {
            email: this.email,
            password: this.password
          }

          this.$store.dispatch('loginUser', user)
          .then(() => {
            this.$router.push('/orders')
            console.log(user)
          })
          .catch(() => {})

          // axios.post(`http://spa/vuecurs/vue2/public/api/`, {
          //   request: "loginUser",
          //   email: this.email,
          //   password: this.password
          // })
          // .then(response => {
          //   console.log(response.data)
          //   this.$router.push('/user/:'+id.response.data)
          //   // this.orders = response.data
          // })
          // .catch(e => {
          //   this.errors.push(e)
          // })
            // .then(() => {
            //   this.$router.push('/')
            // })
            // .catch(err => console.log(err))
        }
      }
    },
    created () {
      if (this.$route.query['loginError']) {
        this.$store.dispatch('setError', 'Для просмотра закрытых разделов необходима авторизация в системе.')
      }
    }
  }
</script>

<style scoped>
.v-card__actions>.v-btn.v-btn {
  padding: 0 24px;
  border-radius: 50px;
}
.content.log-ctnt {
  align-items: center;
  justify-content: center;
  display: flex;
}
.login{
  width: 300px;
}
.head{
  color: #17171894;
  font-weight: bold;
  background-color: #dde1e2;
  margin: 2px;
  padding: 15px;
}
</style>

