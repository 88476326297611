<template>

  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex xs12>
          <v-carousel 
            class="vcarusel" 
            hide-delimiter-background
          >
            <v-carousel-item  class="carusel-index">
              <v-container class="carusCont">
             <v-row dense style="height: 100%;">
              <v-col 
              style="height: 100%;"
              cols="12"
              sm="8"
              md="7"
              >
                <div class="carusel-index-h1">
                  <h2>Прототип приложения обработки заказов</h2>
                  <h1>Vue + Vuetify + MySQL</h1>
                  <p style="color:#000;margin:30px 0;">*Тестовая площадка для модулей и компонентов. <br>**Нет персональных данных, все совпадения случайны.</p>
                  <v-btn :to="'/about'" style="margin-right:10px">Подробнее</v-btn>
                  <v-btn @click="videoalert = !videoalert" color="primary"><v-icon>mdi-arrow-right-drop-circle-outline</v-icon> видео</v-btn>
                </div>
              </v-col>
              <v-col 
              cols="12"
              sm="4"
              md="5"
              >
                <v-img src="https://vue2.fermka.ru/assets/img/screen.png"></v-img>
              </v-col>
             </v-row>
             </v-container>
            </v-carousel-item>


            <v-carousel-item   class="carusel-index"
              v-for="ad in promoAds"
              :key="ad.id"
            >
              <v-container>
             <v-row dense style="height: 100%;">
              <v-col 
              cols="12"
              sm="8"
              md="7"
              >
                <div class="carusel-index-h1">
                  <h2>{{ ad.slogan }}</h2>
                  <h1>{{ ad.title }}</h1>
                  <p style="color:#000;margin:30px 0;">{{ ad.description }}</p>
                  <v-btn :to="'/ad/' + ad.id" style="margin-right:10px">Подробнее</v-btn>
                </div>
              </v-col>
              <v-col 
              cols="12"
              sm="4"
              md="5"
              >
                <v-img :src="ad.srcSmImg"></v-img>
              </v-col>
             </v-row>
             </v-container>

              <!-- <div class="car-link">
                <v-btn class="error" :to="'/ad/' + ad.id">{{ ad.title }}</v-btn>
              </div> -->
            </v-carousel-item>


          </v-carousel>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- <v-container style="
    margin-top: 120px;
    padding: 0 24px;">
      <v-layout row wrap>
        <v-flex
          xs12
          sm6
        ><h3>Consequuntur quis numquam</h3><br>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio necessitatibus maxime voluptatum maiores beatae vero ea rem facilis deleniti?</p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quis numquam at id beatae totam quaerat eum voluptates, animi, eius hic .</p>
        </v-flex>
        <v-flex
          xs12
          sm6
          style="padding-left: 36px;"
        >
        <v-img alt="Logo" src="https://vue2.fermka.ru/assets/img/screen2.png"></v-img>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container class="adofads" grid-list-lg>
      <v-layout row wrap>
        <v-flex
          xs12
          sm6
          md4
          v-for="ad of ads"
          :key="ad.id"
        >
          <v-card>
            <v-img
              :src="ad.imageSrc"
              height="200px"
            >
            </v-img>
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">{{ad.title}}</h3>
                <div>{{ad.description}}</div>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :to="'/ad/' + ad.id">Open</v-btn>
              <v-btn class="primary">Buy</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container> -->
    <v-alert
        class="elevation-20"
        :value="videoalert"
        transition="scale-transition"
      >
        <div class="modal-video">
          
            <div class="video">
              <v-responsive :aspect-ratio="16/9">
                <vue-plyr>
                  <video
                    controls
                    crossorigin
                    playsinline
                    data-poster="https://vue2.fermka.ru/assets/img/logo_mp4.png"
                  >
                    <source
                      size="720"
                      src="https://vue2.fermka.ru/assets/Logo.mp4"
                      type="video/mp4"
                    />
                  </video>
                </vue-plyr>
              </v-responsive>
            </div>
            <div class="video-info">
              <h2>Сделал интро в АЕ пять лет назад </h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam ullam sit consequatur, aliquid veniam autem expedita laudantium eveniet eius, quasi officia veritatis repudiandae possimus rem accusantium quae asperiores non cumque?</p>
              <v-btn icon
                class="btn-video elevation-4"
                @click="videoalert = !videoalert"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          
        </div>
      </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    videoalert: false,
  }),
  computed: {
    promoAds () {
      return this.$store.getters.promoAds
    },
    ads () {
      return this.$store.getters.ads
    }
  }
}
</script>

<style scoped>
.carusCont{
  height: 100%;
}
.carusel-index{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 50px;
  margin-top: 50px;
}

.carusel-index .v-image{
  margin-top: 6%;
  margin-right: 50px;
  max-height: 350px;
}
.v-btn{
  border-radius: 50px;
}
.carusel-index-h1{
 color: #00b3db;
 margin-top: 10%;
 margin-left: 50px;
 margin-right: 24px;
}
.vcarusel{
  background-image: url(https://vue2.fermka.ru/assets/img/bg_concept_40_.jpg);
  background-size: cover;
  box-shadow: inset 0px 15px 10px -20px #000;
}

.adofads{
  margin-top:120px;
  background-image: url(https://vue2.fermka.ru/assets/img/bg2.png);
}
.modal-video{
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 80px);
  margin: 10px;
  padding: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;
}
.video{
  width: 70%;
  background-color: #fff;
}
.video-info{
  background-color: #fff;
  width: 30%;
  padding: 16px;
}
.video-info h2,
.video-info p{
  margin-top: 30px;
}
.v-alert {
  display: contents;
}
.btn-video{
  position: absolute;
  right: 10px;
  top: 10px;
  background: #fff;
}

@media screen and (max-width: 900px) {
  .carusel-index{
    padding: 0px;
  }
  .video,.video-info{
    width: 100%;
  }
  .modal-video{
    position: absolute;
    flex-direction: column;
    height: -webkit-fill-available;
  }
}
@media screen and (max-width: 700px) {
  .carusel-index-h1{
    margin-left: 10px;
  }
  .carusel-index .v-image{
    margin-right: 20px;
  }
}
@media screen and (max-width: 600px) {
  .logo {
    width: auto;
  }
  .carusCont{
    height: auto;
  }
  .carusel-index-h1{
    text-align: center;
    margin:50px 20px;
  }
  .carusel-index .v-image{
    margin-right: 0px;
  }
  .carusel-index .v-image{
    margin-top:0px;
  }
  .carusel-index{
    margin-top: 0;
  }
}
  
</style>
