import Vue from 'vue'
import Vuex from 'vuex'
import shared from './shared'
import ads from './ads'
import radio from './radio'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ads,
    radio,
    shared,
    user
  }
})
