<template>
  <div>
    <ul v-if="orders && orders.length" class="orders">
        <li v-for="order of orders" :key="order.id" class="order">

        <div class="number">{{order.number}}</div>
        <div class="name_buyer">{{order.name_buyer}}</div>
        <div class="contact_email">{{order.contact_email}}</div>
        <div class="phone">{{order.phone}}</div>
        <div class="summ">{{order.summ}}</div>
        
        </li>
    </ul>
    <ul v-if="errors && errors.length">
        <li v-for="error of errors" :key="error.id">
        {{error.message}}
        </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      orders: [],
      errors: [],
    }
  },
  methods: {
  },

  created() {
    axios.post(`https://vue2.fermka.ru/api/`, {request: "get_Order"})
    .then(response => {
      this.orders = response.data
    })
    .catch(e => {
      this.errors.push(e)
    })
  }
}
</script>

<style scoped>
.orders{
  width: 100%;
  padding-left: 0;
}
.order{
  width: 100%;
  display: flex;
}
.order div{
  padding: 4px;
  margin: 1px;
  background-color: #cccccc61;
}
.number{
  min-width: 100px;
  color: #f00;
}
.name_buyer{
  width: 100%;
}
.contact_email{
  width: 100%;
}
.phone{
  width: 100%;
}
.summ{
  min-width: 100px;
  color: green;
}
@media screen and (max-width: 900px) {
  .order{
    flex-direction: column;
  }
  .number{
    margin-top: 16px!important;
  }
  .order div{
    margin:0;
  }

}
</style>>

</style>