<template>
  <div class="wrap">
      <div class="content">
        <v-img
          :src="ad.srcBigImg"
          height="200px"
        ></v-img>
        <v-container>  

          <v-card-actions v-if="isUserLoggedIn">
            <v-spacer></v-spacer>
            <!-- <v-btn class="warning">Изменить</v-btn> -->
            <v-btn @click="delAd" class="error">Удалить</v-btn>
          </v-card-actions>
          
          <v-flex xs12 sm10 offset-sm1>  
            
            <h1 class="text--primary" style="margin: 24px 0;">{{ad.title}}</h1>
            <p>{{ad.description}}</p>
            <p v-html="ad.text_ad"></p>
            
            
          </v-flex>
        </v-container>
      </div>
      <div class="footer">

      </div>
  </div>
</template>

<script>
  export default {

    props: ['id'],
    computed: {
      ad () {
        const id = this.id
        return this.$store.getters.adById(id)
      },
      error () {
        return this.$store.getters.error
      },
      isUserLoggedIn () {
        return this.$store.getters.isUserLoggedIn
      },
    },
    methods: {
      delAd () {
        // alert(this.ad.id)
        this.$store.dispatch('delAd', this.ad)
          .then(() => {
            this.$router.push('/list')
          })
          .catch(() => {})
      }
      
      
    },
    metaInfo() {
      // const id = this.id
      // const ad = this.$store.getters.adById(id)
      // const title = ad.title
      return { 
        title: this.$store.getters.adById(this.id).title
      }
    },
  }
</script>

<style scoped>


</style>
