<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
                v-model="email"
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                name="password"
                label="Пароль"
                type="password"
                :counter="6"
                v-model="password"
                :rules="passwordRules"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                name="confirm-password"
                label="Повторить пароль"
                type="password"
                :counter="6"
                v-model="confirmPassword"
                :rules="confirmPasswordRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit" 
              :loading="loading"
              :disabled="!valid || loading"
            >СОЗДАТЬ ПРОФИЛЬ</v-btn>
          </v-card-actions>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  export default {
    data () {
      return {
        email: '',
        password: '',
        confirmPassword: '',
        valid: false,
        emailRules: [
          v => !!v || 'E-mail обязателен!',
          v => emailRegex.test(v) || 'E-mail не валидный'
        ],
        passwordRules: [
          v => !!v || 'Пароль обязателен!',
          v => (v && v.length >= 6) || 'Пароль должен содержать больше 6 символов'
        ],
        confirmPasswordRules: [
          v => !!v || 'Пароль обязателен!',
          v => v === this.password || 'Пароли не совпадают!'
        ],
        orders: [],
        errors: [],

      }
    },
    computed: {
      loading () {
        return this.$store.getters.loading
      }
    },
    methods: {
      onSubmit () {
        if (this.$refs.form.validate()) {
          const user = {
            email: this.email,
            password: this.password
          }

          this.$store.dispatch('registerUser', user)
          .then(() => {
            this.$router.push('/').catch(()=>{});
          })
          .catch(() => {})

        }
      }
    }
  }
</script>

<style scoped>
.v-card__actions>.v-btn.v-btn {
    padding: 0 24px;
    border-radius: 50px;
}
</style>
