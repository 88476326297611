<template>
<div class="wrap">
    <div class="content">
        <h1>404</h1>
    </div>
    <div class="footer">

    </div>
</div>
</template>

<script>
export default {
    metaInfo: {
    title: '404 | Нет такой страницы!'
    },
    data () {
      return {
        
      }
    },
}
</script>

<style scoped>
.content{
    display: flex;
    align-items: center;
    justify-content: center;
}
h1{
    color: #00b3db ;
    font-size: 150px;
}

</style>
